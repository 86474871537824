<template lang="pug">
  .row
    .col
      h5 Авторизация

      form.form(@submit.prevent="doLogin")
        .form-group
          input.form-control(type="text" id="login" placeholder="E-mail или телефон" v-model="login" required)

        .form-group
          input.form-control(type="password" id="password" placeholder="Пароль" v-model="password" required)

        .form-group
          router-link(:to="{ name: 'sendResetPasswordInstructionsForm' }") Забыли пароль?

        button.r-coach-btn(type="submit") Войти
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      login: '',
      password: ''
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'login'
    }),
    doLogin () {
      const params = {
        login: this.login,
        password: this.password
      }

      this.loginAction(params).then((response) => {
        if (response.token) {
          this.$notify({
            title: 'Авторизация',
            type: 'success',
            text: 'Вы успешно авторизовались. Через несколько секунд Вы будете перемещены в свой кабинет'
          })

          window.location.href = '/'
        }
      }).catch(() => {
        this.$notify({
          title: 'Авторизация',
          type: 'error',
          text: 'Авторизация не удалась. Проверьте логин и пароль'
        })
      })
    }
  }
}
</script>
